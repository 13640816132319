import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink
} from '@apollo/client';
import { ReactNode, createContext, useMemo } from 'react';

import NatomaHTTPClient from '../lib/http';
import useEnvironment from './Environment';
import { Service } from '@/__generated__/graphql';

interface ApiContextValues {
  apiClient?: ApolloClient<NormalizedCacheObject>;
  httpClient?: NatomaHTTPClient;
}

// the __ref string holds value like Service:[ObjectId]
type IncomingService = Service & { __ref: string };

export const ApiContext = createContext<ApiContextValues>({});

export function ApiProvider({ children }: { children: ReactNode }) {
  const { natoma } = useEnvironment();

  const apiClient = useMemo(() => {
    const link = createHttpLink({
      uri: `https://api.${natoma.rootDomain}/graphql`,
      credentials: 'include'
    });

    return new ApolloClient({
      // TODO: Move to env variable
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              services: {
                // only cache integrationId and isIntegration
                keyArgs: ['integrationId', 'isIntegration'],
                merge(existing = [], incoming) {
                  const merged = new Map<string, IncomingService>();

                  // Add existing items to the map
                  existing.forEach((item: IncomingService) => {
                    merged.set(item.__ref, item);
                  });
                  // Add incoming items to the map
                  incoming.forEach((item: IncomingService) => {
                    merged.set(item.__ref, item);
                  });
                  // Convert map values to an array
                  return Array.from(merged.values());
                }
              },
              users: {
                keyArgs: ['status', 'org'],
                merge(_, incoming) {
                  return incoming;
                }
              }
            }
          }
        }
      }),
      link
    });
  }, [natoma]);

  // We can move this elsewhere, Should handle any potentially unauthed calls
  // fine since it's auth mechanism will be cookies and pulling an org out of query params.
  const httpClient = useMemo<NatomaHTTPClient>(
    () => new NatomaHTTPClient(natoma.rootDomain),
    [natoma.rootDomain]
  );

  return (
    <ApiContext.Provider value={{ apiClient, httpClient }}>
      <ApolloProvider client={apiClient}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
}
